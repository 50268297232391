.headingModal {
  background-color: #ff930f;
}
.btn-yellow-modal {
  background-image: linear-gradient(to bottom, #ff930f, #fff95b);
  align-items: center;
  height: 70px;
  width: 90px;
  border: none;
}
.btn-yellow-modal > span {
  font-size: 20px;
}
.timerDigitDiv {
  padding: 5px 10px;
}

.timerDigit {
  color: #f1f1f1;
}

.timerDigitGray {
  color: #bebebe !important;
}
/* Card Css */
.card-tag {
  position: relative;
  margin-left:3.125rem
}
.plateTag {
  position: absolute;
  left: -50px;
  /* right: 23px; */
  top: 36px;
  z-index: 10;
  width: 66px;
  height: 80px;
}
/* playbtn card css */
.playbtn {
  height: 45px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bgplaygreen {
  background-color: #3cb043;
}
.bgplaygray {
  background-color: #a8a8a8;
}
.slick-slider {
  margin-left: 50px;
}
ul {
  list-style-type: none;
}
.footStep-btn {
  position: absolute;
  top: 1px;
  right: 1px;
  background-image: linear-gradient(
    to right top,
    #eccd00,
    #f0b400,
    #f19a00,
    #f07f00,
    #ec6200,
    #eb530a,
    #ea4114,
    #e82c1b,
    #eb2623,
    #ee202a,
    #f11830,
    #f40e37
  );
  border-style: none !important;
  padding: 10px;
}
@media screen and (min-width: 1100px) {
  .slick-slider {
    margin-left: auto !important;
  }
}
@media screen and (max-width: 400px) {
  .slick-slider {
    margin-left: auto;
  }
  /* .card-tag {
    margin-left:0
  } */
}

.cook-content-modal{
  background-color: #f0ede8;
}
.confirm-btn{
  border:none;
  background-color: #20c100c4;
  color: white;
  border-radius: 5px;
}
.confirm-btn:hover{
  background-color: #21c100;
  
}
.gray-btn{
  border:none;
  padding: 5px;
  background-color: #575756;
  color: white;
  border-radius: 5px;
}
.gray-btn:hover{
  background-color: #95a5a6;
}
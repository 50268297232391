.bottom-footer {
  background-color: #1c343d !important;
}

.fixed-bottom {
  bottom: -12px !important;
}
.bottom-small-content {
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  color: #fff !important;
  font-weight: 400;
  height: 25px;
}
.main-box {
  width: 100%;
  margin-top: -15px;
  padding-left: 0px;
  margin-bottom: 0px;
}
.bgGray {
  background-color: #868686 !important;
}

.btn-Gray {
  text-align: center;
  /* padding: 7px; */
  font-size: 14px;
  width: 120px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  font-weight: 800;
  background: linear-gradient(
    149deg,
    rgba(151, 150, 150, 1) 34%,
    rgba(60, 69, 74, 1) 92%
  );
  height: 80px ;
}
.btn-Gray > img {
  height: 30px !important;
  width: 30px;
}
.gray-icon > img {
  height: 30px !important;
  width: 30px;
}
.btn-group-bottom {
	height: 60px !important;
	padding: 0px 25px;
}
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.priority-btn {
  height: 72px !important;
  background: linear-gradient(
    97deg,
    rgba(228, 38, 32, 1) 11%,
    rgba(234, 90, 38, 1) 65%
  ) !important;
  border: none !important;
  width: 100px !important;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}
.botm-center-btn {
  background-color: #1c343d !important;
  border-color: #1c343d !important ;
  margin-bottom: 2px;

}
.botm-center-btn > img{
height: 30px !important;
width: 30px;
}
.botm-center-btn-active > img{
  height: 30px !important;
  width: 30px;
  }
.botm-center-btn > h6 {
  font-size: 14px;
  color: #f1f1f1 !important;
}
.botm-center-btn-active > h6 {
  color: #2eaaa1;
}
.botm-center-btn-active {
  height: 100%;
}
.order-function {
  background-color: #2eaaa1;
  height: 20px;
  font-size: 14px;
  width: 95%;
}
.table-function {
  background-color: #218ced;
  height: 20px;
  font-size: 14px;
}

.stations-input{
    border: 1px solid #adb5bd;
    border-radius: 10px;
    padding: 0.225rem;
}
.stations-input a{
    
   text-decoration: none;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.stations-input .muted{
    
   background-color: #f8f9fa;
   border: 1px solid #adb5bd
 }
.header-top {
	height: 60px;
	background-color:#1c343d !important;
}
.logo-div {
	height: 60px;
    font-size: 36px;
    color: #f1f1f1;
	align-items: center;
}
.navbar-nav{
	margin-top: 0;
}
.btn-group {
	height: 70px;
	padding: 0px 25px;
}
.live-btn {
	height: 100%;
	padding: 0 25px;
}
.live-btn:hover {
	background: linear-gradient(153deg, #00a099 28%, #096463 96%);
    cursor: default;

}
.bg-dark{
	background-color: white !important;
}
.topCenter-btns {
	height: 100%;
	padding: 0 25px;
	display: flex;
	align-items: center;
	font-size: 26px;
}
.topCenter-btns:hover {
	background: linear-gradient(153deg, #00a099 28%, #096463 96%);
	color: #f1f1f1;
    cursor: default;
}
.active {
	background: linear-gradient(153deg, #00a099 28%, #096463 96%);
    color: #f1f1f1;
}
.sliver {
	background: linear-gradient(
		146deg,
		rgba(245, 241, 236, 1) 39%,
		rgba(175, 177, 178, 1) 95%
	);
	color: black;
    
}
.recipe-btn{
	text-decoration: none;
	color: black;
}
 a:hover{
	color: #f1f1f1 !important;
}

.table-card{
    position: relative;
    display: flex;
    align-items: center;
    height: 90px;
    width: 132px;
    margin: 10px;
    box-shadow: 1px 1px 6px -1px #474747;
    cursor: pointer;
}
.numberSec{
    display: flex;
    height: 100%;
    align-items: center;
    width: 65%;
    justify-content: center;
    font-size: 36px;
    font-weight: 700;
    font-family: 'Montserrat',sans-serif;
}
.item-overlay{
    position: absolute; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
.numberSec > span{
    font-size: 20px;
    word-break: break-all;
}
.partionSec{
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 35%;
    justify-content: center;
}
.resTb-on,.resTb-so{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    font-weight: bold;
}

.bgNo-grey{
    background-color: #ECECEC;
}
.bgNo-green{
    background-color: #93C01F;
}
.bgNo-pink{
    background-color: #CC7A9A;
}
.bgTable-blue{
    background-color: #35A8E0;
}
.bgTable-green{
    background-color: #21C100;
}
.bgTable-orange{
    background-color: #F8B133;
}
.noTb.bgNo-pink{
    color: #fff;
}
@media  only screen and (max-width: 1366px) {
    .numberSec > span{
        font-size: 18px;
       
    }
}
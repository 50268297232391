.main-content {
  min-height: 100vh;
  overflow-y: hidden;
  background: #1c343d;
}

.logo {
  display: flex;
  justify-content: center;
  object-fit: cover;
}

.login-card {
  margin-left: 50px;
  background-image: linear-gradient(315deg, #050505 -16%, #203238 60%);
  height: 500px;
  border-radius: 10px;
}
.loader-login{
  background-color: #1c343d; 
}

.btn-yellow-login {
  background: linear-gradient(
    153deg,
    rgba(248, 177, 51, 1) 28%,
    rgba(233, 86, 29, 1) 92%
  );
  border: 2px solid black;
  height: 75px;
  width: 170px;
  border-radius: 10px;
  color: black;
}

.btn-gray {
  background: linear-gradient(to bottom right, #eeebeb 0%, #636360 100%);
  border: 2px solid black;
  height: 75px;
  width: 170px;
  border-radius: 10px;
  color: black;
}

.input-style {
  background-color: #0f1411 !important;
  height: 70px !important;
  border: 1px solid #1c343d !important;
}


.numberBtn {
  height: 75px;
  width: 75px;
  background: linear-gradient(90deg, rgb(19, 73, 73) 0%, rgb(20, 22, 22) 100%);
  border-radius: 10px;
  border: 1px solid rgb(19, 73, 73);
  border-right: #050505;
  color: #f3f3f3;
  font-size: 36px;
}

.btnSymbol {
  height: 75px;
  width: 75px;
  background: linear-gradient(
    90deg,
    rgb(27, 216, 216) 0%,
    rgb(42, 104, 104) 100%
  );
  border-radius: 10px;
  border: 1px solid rgb(19, 73, 73);
  border-right: #047a7a;
  color: #f3f3f3;
  font-size: 36px;
}
.logo-btn {
  height: 50px;
}

@media only screen and (max-width: 1385px) {
  .btnSymbol {
    height: 60px;
    width: 60px;
    font-size: 16px;
  }
  .numberBtn {
    height: 60px;
    width: 60px;
    font-size: 16px;
  }
  .btn-gray {
    height: 50px;
    width: 50%;
    font-size: 16px;
  }
  .btn-yellow-login{
        height: 50px;
        width: 50%;
        font-size: 16px;
        
      
    }
  .logo-btn {
    height: 20px;
  }
}
@media only screen and (max-width: 450px) {
  .login-card {
    height: 400px;
  }
 
  
	.btnSymbol {
	  height: 50px;
	  width: 45px;
	  font-size: 16px;
	}
	.numberBtn {
	  height: 50px;
	  width: 45px;
	  font-size: 16px;
	}
	.btn-gray {
	  height: 50px;
	  width: 50%;
	  font-size: 16px;
	}
	.btn-yellow-login{
		  height: 50px;
		  width: 50%;
		  font-size: 16px;
		  
		
	  }
	.logo-btn {
	  height: 20px;
	}
  }
  @media only screen and (max-width: 1366px) {
    .login-card{
      height: 400px;
    }
  }

.session-group{
    background-color: #dfe6e9;
    border-radius: 5px;
}
.heading-seesion{
    font-size: 18px;
    font-weight: bold;

}
.badge-bt{
    border:1px solid #636e72
}
.cross-button{
    background-color: #d63031;
   height: 30px;
   border-radius: 80%;
}
.tablename-change-textbox{
    border-radius:20px;
    border: none;

}
.selected-table{
    width: 50%;
}
.selected-table > input {
width: 95%;
}
.selected-table> label{
    font-size: 14px;
    font-weight: bold;
}
.ok-btn{
    width: 150px;
    border: none;
    background-color: #3498db;
    border-radius: 25px;
    color: white;
}
.badg-pointer {
    cursor: pointer;
    font-family:monospace;
    font-size: 12px;
    font-weight: lighter;
}